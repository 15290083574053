<template>
  <b-container class="wrap">
    <Logout />
    <SearchForm @searchUser="searchUser" @searchReset="searchReset" />

    <div v-if="searchAccount">
      <UserInfo :account="searchAccount.user" />
      <Orders />
    </div>
    <div v-if="searchData && searchData.length > 1">
      <AccountsList :accounts="searchData" />
    </div>

  </b-container>
</template>

<script>
import { useSearch } from '@/use/search'
import Logout from '@/components/Logout'
import SearchForm from '@/components/SearchForm'
import UserInfo from '@/components/UserInfo'
import Orders from '@/components/Orders'
import AccountsList from '@/components/AccountsList'

export default {
  components: { Logout, SearchForm, UserInfo, Orders, AccountsList },
  computed: {
    searchData () {
      return this.$store.state.search.searchData
    },
    searchAccount () {
      return this.$store.state.search.searchAccount
    }
  },
  setup () {
    const { getSearchData } = useSearch()
    return { getSearchData }
  },
  methods: {
    async searchUser (uid, email, orderId) {
      if (!uid && !email && !orderId) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Please input UserId, Email or OrderId',
          timeout: 2000,
          name: 'empty_search_warning'
        })
        return
      }
      this.$store.commit('loaders/setSearchLoading', true)
      await this.getSearchData(uid, email, orderId)
      this.$store.commit('loaders/setSearchLoading', false)
    },
    searchReset () {
      this.$store.commit('search/setSearchData', null)
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrap{
    margin-top: 20px;
  }
</style>
