<template>
  <div class="container clear-layout">
    <div class="clear-layout__logo">
      <img
        src="@/assets/logo.svg"
        alt="Esim support area"
        style="margin-right: 5px"
      />
      <img src="@/assets/logo-text.svg" alt="Esim support area" />
    </div>

    <div class="clear-layout__main">
      <div class="create-account">
        <transition name="fade">
          <div>
            <h1 class="auth-title__main title__main--sm title__main--pb0 mb-2">Admin Login</h1>
            <LoginBySocials />

            <div class="login-delimiter">
              <svg width="348" height="1" viewBox="0 0 348 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.5" y1="0.5" x2="347.5" y2="0.5" stroke="#14142B" stroke-opacity="0.2" stroke-dasharray="5 5"/>
              </svg>
              <span>OR</span>
            </div>

            <LoginByEmail />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import LoginBySocials from '@/components/Login/LoginBySocials/LoginBySocials.vue'
import LoginByEmail from '@/components/Login/LoginByEmail/LoginByEmail.vue'

export default {
  components: { LoginBySocials, LoginByEmail },
  mounted () {
    if (this.$store.state.auth.isAuthenticated) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .clear-layout {
    margin-top: 100px;
    margin-bottom: 40px;
    display: flex;
    align-content: center;
    flex-direction: column;

    &__logo {
      text-align: center;
      margin-bottom: 32px;
      img {
        cursor: pointer;
      }
    }

    &__main {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 590px) {
    .clear-layout {
      margin-top: 30px;
      &__logo {
        margin-left: auto;
        margin-right: auto;
        width: 86px;
        height: 84px;
        img {
          width: 100%;
        }
      }
    }
  }
  .create-account {
    position: relative;
    width: 400px;

    .auth-title__main{
      text-align: center;
      line-height: 30px;
      letter-spacing: -0.2px;
      font-size: 26px;
      font-weight: 600;
    }
    .auth-title__subtitle{
      font-size: 14px;
      line-height: 24px;
      color: #14142B;
      opacity: 0.6;
    }
  }

  .login-delimiter {
    position: relative;
    max-width: 347px;
    margin: 0 auto;
    height: 28px;

    svg {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      top: -8px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 500;
      font-size: 12px;
      color: rgba(20, 20, 43, 0.6);
      padding: 0 7px;
      background: #FFF;
    }
  }
</style>
