import { useStore } from 'vuex'
import { useEsimAdminApi } from '@/use/esimAdminApi'
import { useErrorHandler } from '@/use/errorHandler'

export function useSearch () {
  const store = useStore()
  const { initApiInst, esimUserApi } = useEsimAdminApi()
  const { handleCommonErrors } = useErrorHandler()

  const getSearchData = async (uid, email = '', orderId = '') => {
    if (!uid && !email && !orderId) return
    try {
      await initApiInst()
      const searchData = await esimUserApi.value.getUser({ params: { email, uid, orderId } })
      store.commit('search/setSearchData', searchData.data)
    } catch (e) {
      handleCommonErrors(e)
      store.commit('search/setSearchData', null)
    }
  }

  return { getSearchData }
}
