import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import BootstrapVue3 from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  appId: process.env.VUE_APP_FB_APP_ID
}

initializeApp(firebaseConfig)
const auth = getAuth()

let app
onAuthStateChanged(auth, async () => {
  await store.dispatch('auth/setUserData')

  if (!app) {
    app = createApp(App)
      .use(store)
      .use(router)
      .use(BootstrapVue3)
      .mount('#app')
  }
})
