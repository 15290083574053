<template>
  <b-modal
    v-model="showModal"
    @hide="hideHide"
    @keydown.enter="savePartner"
    :title="(account.user && account.user.isPartner ? 'Update' : 'Set user as') +  ' Partner'"
    class="setPartnerModal"
    centered
    hide-footer
  >
    <div class="mb-2">
      <b-form-checkbox
        switch
        size="lg"
        v-model="isPartner"
      >
        Is partner
      </b-form-checkbox>
    </div>
    <div class="mb-3">
      <label class="mr-sm-2">Partner name</label>
      <b-form-input v-model="name" :state="errors.includes('name') ? false : null" />
    </div>
    <div class="mb-3">
      <label class="mr-sm-2">Partner code (must be unique)
        <span class="changeCodeWarning">(if you change a code - new one will be created and the old also will be available)</span>
      </label>
      <b-form-input v-model="code" :state="errors.includes('code') ? false : null" />
    </div>
    <div class="mb-3">
      <label class="mr-sm-2">Partner commission type</label>
      <b-form-select v-model="type" :options="typeOptions" :state="errors.includes('type') ? false : null" />
    </div>
    <div class="btnBl">
      <b-button @click="$store.commit('modal/togglePartnerModal', false)" variant="light">Cancel</b-button>
      <b-button @click="savePartner" variant="danger" class="confirmBtn" :disabled="loading">Save partner</b-button>
    </div>
  </b-modal>
</template>

<script>
import { useEsimAdminApi } from '@/use/esimAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import { useSearch } from '@/use/search'

export default {
  data () {
    return {
      loading: false,
      isPartner: true,
      name: '',
      code: '',
      type: null,
      typeOptions: [
        { value: 'fixed', text: 'Fixed' },
        { value: 'percent', text: 'Percent' },
        { value: 'both', text: 'Both' }
      ],
      errors: []
    }
  },
  computed: {
    account () {
      return this.$store.state.search.searchAccount ?? null
    },
    showModal: {
      get () {
        return this.$store.state.modal.showPartnerModal
      },
      set () {}
    }
  },
  watch: {
    account (val) {
      if (val) {
        this.initPartnerData(val.user)
      }
    },
    showModal (val) {
      if (val) {
        this.errors = []
        this.initPartnerData(this.account.user)
      }
    }
  },
  setup () {
    const { initApiInst, esimUserApi } = useEsimAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    const { getSearchData } = useSearch()
    return { initApiInst, esimUserApi, handleCommonErrors, getSearchData }
  },
  mounted () {
    if (this.account.user) {
      this.initPartnerData(this.account.user)
    }
  },
  methods: {
    hideHide () {
      if (this.showModal) {
        this.$store.commit('modal/togglePartnerModal', false)
      }
    },
    async savePartner () {
      this.validateForm()
      if (this.errors.length > 0) return
      this.loading = true
      try {
        await this.initApiInst()
        const res = await this.esimUserApi.createUpdatePartner({
          user_id: this.account.user.id,
          is_partner: this.isPartner,
          name: this.name,
          code: this.code,
          commission_type: this.type
        })
        if (res.data && res.data.result === 'success') {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Partner successfully ' + (this.account.user.isPartner ? 'updated' : 'added'),
            timeout: 3000,
            type: 'success',
            name: 'success_partner_change'
          })
          await this.getSearchData(this.account.user.uid)
          this.hideHide()
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.loading = false
      }
    },
    validateForm () {
      this.errors = []
      if (!this.name) this.errors.push('name')
      if (!this.code) this.errors.push('code')
      if (!this.type) this.errors.push('type')
    },
    initPartnerData (partner) {
      this.name = partner.partnerName ?? ''
      this.code = partner.partnerCode ?? ''
      this.type = partner.partnerCommissionType ?? null
    }
  }
}
</script>

<style lang="scss">
  .setPartnerModal{
    .form-check-label{
      font-size: 16px;
    }
    .btnBl{
      display: flex;
      justify-content: flex-end;
      margin: 30px 0 5px;
      .confirmBtn{
        margin-left: 16px;
      }
    }
  }
  .changeCodeWarning{
    display: block;
    font-size: 12px;
    color: #cc7e42;
  }
</style>
