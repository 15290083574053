export default {
  namespaced: true,
  state: {
    searchData: null,
    searchAccount: null
  },
  mutations: {
    setSearchData: (state, val) => {
      state.searchAccount = null
      state.searchData = val
      if (val && val.length === 1) {
        state.searchAccount = val[0]
      }
    },
    setSearchAccount: (state, account) => {
      state.searchAccount = account
    }
  }
}
