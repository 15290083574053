<template>
  <b-row v-if="searchAccount.orders" class="purchasesWrap">
    <b-col cols="12" class="my-3">
      <div class="header">Orders</div>
    </b-col>
    <b-col class="my-2">
      <div class="table-responsive mb-5">
        <table class="table">
          <thead>
            <tr>
              <th class="">Date</th>
              <th class="">Order #</th>
              <th class="">Payment #</th>
              <th class="">Payment method</th>
              <th class="text-center">Total</th>
              <th class="">Sim #</th>
              <th class="">Country/Region</th>
              <th class="">Valid days</th>
              <th class="">Data</th>
              <th class="">Type</th>
              <th class="">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in orderArr" :key="index" class="">
              <td>{{ format(new Date(row.date * 1000), 'yyyy-MM-dd HH:mm:ss') }}</td>
              <td @click="copyToCb($refs['order' + index][0], row.id)" class="cursorPointer">
                <span :ref="`order${index}`">{{ row.id }}</span>
              </td>
              <td @click="copyToCb($refs['payment' + index][0], row.paymentId)" class="cursorPointer">
                <span :ref="`payment${index}`">{{ row.paymentId }}</span>
              </td>
              <td class="">{{ row.gateway === 'gplay' ? 'Google Play' : (row.gateway === 'appstore' ? 'Itunes' : row.gateway.charAt(0).toUpperCase() + row.gateway.slice(1)) }}</td>
              <td class="text-center">{{ row.total }}</td>
              <td @click="copyToCb($refs['sim' + index][0], row.esimId)" class="cursorPointer">
                <span :ref="`sim${index}`">{{ row.esimId }}</span>
              </td>
              <td class="text-center">{{ row.country || row.region }}</td>
              <td class="text-center">{{ row.validityDays }}</td>
              <td class="text-center">{{ row.DataBytes }}</td>
              <td class="text-center">{{ row.productType }}</td>
              <td><b-button  v-if="row.productType=='plan'" @click="replaceQuestion(row.esimId, row.total)" variant="light" class="confirmBtn">Replace esim</b-button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>

  <b-modal v-model="replaceModal" @keydown.enter="replace" title="Are you sure?" class="questionModal" centered hide-footer>
    <div class="btnBl">
      <b-button @click="this.replaceModal = false" variant="light">Cancel</b-button>
      <b-button @click="replace" variant="danger" class="confirmBtn">Replace esim #{{esimId}} amount: ${{ amount }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import { format } from 'date-fns'
import { useSearch } from '@/use/search'
import { useEsimAdminApi } from '@/use/esimAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import { useCopy } from '@/use/copy'

export default {
  data () {
    return {
      format,
      orderArr: [],
      amount: 0,
      esimId: null,
      replaceModal: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.loaders.commonLoading
    },
    searchAccount () {
      return this.$store.state.search.searchAccount
    }
  },
  watch: {
    searchAccount (val) {
      if (val) {
        this.formOrdersArr()
      }
    }
  },
  setup () {
    const { getSearchData } = useSearch()
    const { initApiInst, simApi } = useEsimAdminApi()
    // const { initApiInst, smsPurchasesApi } = useSmsAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    const { copyToCb } = useCopy()
    return { getSearchData, initApiInst, simApi, handleCommonErrors, copyToCb }
  },
  mounted () {
    this.formOrdersArr()
  },
  methods: {
    formOrdersArr () {
      this.orderArr = []
      if (this.searchAccount.orders.length > 0) {
        this.searchAccount.orders.map(order => {
          this.orderArr.push(order)
        })
      }
    },
    replaceQuestion (id, amount) {
      this.clearReplaceData()
      this.esimId = id
      this.amount = amount
      this.replaceModal = true
    },
    async replace () {
      this.$store.commit('loaders/setCommonLoading', true)
      try {
        await this.initApiInst()
        const resp = await this.simApi.replaceSim(this.esimId)
        if (resp && resp.data && resp.data.id) {
          await this.getSearchData(this.searchAccount.user.uid)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Esim successfully replaced',
            timeout: 3000,
            type: 'success',
            name: 'success_replaced'
          })
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        console.log('finally')
        this.replaceModal = false
        this.$store.commit('loaders/setCommonLoading', false)
        this.clearReplaceData()
      }
    },
    clearReplaceData () {
      this.amount = 0
      this.esimId = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .purchasesWrap{
    margin-top: 50px;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }

    .header{
      font-size: 24px;
      border-bottom: 1px solid #717171;
    }

    .table{
      td{
        padding: 15px 10px;
        vertical-align: middle;

        .refundBl{
          display: flex;
          .refundInput{
            width: 120px;
            margin-right: 20px;
          }
        }
        .refundTr{
          padding-left: 5px;
          .refundDot{
            padding: 0 10px;
          }
        }
      }
    }

    .refundBtn{
      min-width: 100px;
      @media screen and (max-width: 600px) {
        min-width: 80px;
      }
    }
  }
  .cursorPointer{
    cursor: pointer;
  }
</style>
