<template>
  <b-row>
    <b-col>
      <div class="float-end mb-4">
        <b-button @click="logout" variant="outline-warning">Logout</b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { useAuth } from '@/use/auth'

export default {
  setup () {
    const { logout } = useAuth()
    return { logout }
  }
}
</script>
