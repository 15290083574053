<template>
  <b-row v-if="account" class="userInfoWrap">
    <b-col lg="4" class="userInfo my-3">
      <div v-if="account.email" @click="copyToCb($refs.email, account.email)" class="email"><span class="greyText">Email:</span> <span ref="email">{{ account.email }}</span></div>
      <div v-if="account.uid" @click="copyToCb($refs.uid, account.uid)" class="uid"><span class="greyText">User Id:</span> <span ref="uid">{{ account.uid }}</span></div>
      <div><span class="greyText">Status:</span> {{ account.status }}</div>
      <div>
        <span class="greyText">Is partner:</span>
        {{ account.isPartner ? 'Yes' : 'No' }}
        <a
          v-if="account.isPartner"
          @click="$store.commit('modal/togglePartnerInfoModal', true)"
          class="btn btn-outline-success showPartnerInfoBtn"
        >
          Partner info
        </a>
      </div>
    </b-col>
    <b-col lg="3" class="my-3">
      <div><span class="greyText">Last country:</span> {{ account.lastCountry ? account.lastCountry.toUpperCase() : '' }}</div>
      <div><span class="greyText">Register country:</span> {{ account.regCountry ? account.regCountry.toUpperCase() : '' }}</div>
      <div><span class="greyText">Referral points:</span> ${{ account.pointBalance ? account.pointBalance : 0 }}</div>
      <b-row class="statusSelectWrap">
        <b-col xs="12" class="px-xl-2">
          <StatusSelect :account="account" />
        </b-col>
      </b-row>
    </b-col>
    <b-col lg="3">
      <a :href="grafanaUrl + account.uid" target="_blank" class="btn btn-outline-success btn-block mb-3">Statistics ⟶</a>
      <a @click="addPointsQuestion()" target="_blank" class="btn btn-outline-success btn-block mb-3">Add points</a>
      <a @click="$store.commit('modal/togglePartnerModal', true)" target="_blank" class="btn btn-outline-success btn-block mb-3 mb-xl-5">
        {{ account.isPartner ? 'Update' : 'Set user as' }} partner
      </a>
    </b-col>
  </b-row>
  <b-modal v-model="addPointsModal" @keydown.enter="addPoints" title="Add points to user balance? Amount in $" class="questionModal" centered hide-footer>
      <p class="my-2"><input type="number" v-model="amount" class="form-control" placeholder="Amount" /></p>
    <div class="btnBl">
      <b-button @click="this.addPointsModal = false" variant="light">Cancel</b-button>
      <b-button @click="addPoints" variant="danger" class="confirmBtn">Add points</b-button>
    </div>
  </b-modal>
  <SetPartnerModal />
  <PartnerInfoModal />
</template>

<script>
import { useSearch } from '@/use/search'
import { useErrorHandler } from '@/use/errorHandler'
import { useCopy } from '@/use/copy'
import { useEsimAdminApi } from '@/use/esimAdminApi'
import StatusSelect from '@/components/StatusSelect'
import SetPartnerModal from '@/components/modals/SetPartner'
import PartnerInfoModal from '@/components/modals/PartnerInfo'

export default {
  props: {
    account: Object
  },
  emits: ['searchUser', 'searchReset'],
  components: { StatusSelect, SetPartnerModal, PartnerInfoModal },
  data () {
    return {
      grafanaUrl: process.env.VUE_APP_GRAFANA_URL,
      amount: 0,
      addPointsModal: false
    }
  },
  computed: {
    searchAccount () {
      return this.$store.state.search.searchAccount
    },
    loading () {
      return this.$store.state.loaders.commonLoading
    }
  },
  setup () {
    const { getSearchData } = useSearch()
    const { handleCommonErrors } = useErrorHandler()
    const { copyToCb } = useCopy()
    const { initApiInst, esimUserApi } = useEsimAdminApi()
    return { getSearchData, handleCommonErrors, copyToCb, initApiInst, esimUserApi }
  },
  methods: {
    addPointsQuestion () {
      this.amount = 0
      this.addPointsModal = true
    },
    async addPoints () {
      this.$store.commit('loaders/setCommonLoading', true)
      this.addPointsModal = false
      try {
        await this.initApiInst()
        const resp = await this.esimUserApi.addUserPoints({
          uid: this.searchAccount.user.uid,
          amount: this.amount
        })
        if (resp && resp.data && resp.data.result === 'success') {
          await this.getSearchData(this.searchAccount.user.uid)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Points successfully added',
            timeout: 3000,
            type: 'success',
            name: 'success_replaced'
          })
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .userInfoWrap{
    margin-top: 60px;
    @media screen and (max-width: 767px) {
      margin-top: 40px;
    }

    .userInfo{
      >div{
        font-size: 18px;
        margin-bottom: 7px;
        &.email,
        &.uid{
          cursor: pointer;
        }
        span.greyText{
          color: #7e7e7e;
        }
      }
    }
    .accountActionBtn{
      width: 100%;
      display: block;
      padding-left: 4px;
      padding-right: 4px;
    }
    .mr-10px{
      margin-right: 10px;
    }
    .deletedAccount{
      display: flex;
      align-items: center;
      color: #FF4757;
      font-size: 20px;
    }
    .statisticsButtonsWrap{
      a{
        max-width: 180px;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .statusSelectWrap{
      margin-top: 19px;
      margin-right: 20px;
      @media screen and (max-width: 767px) {
        margin-right: -10px;
      }
    }
    .showPartnerInfoBtn{
      padding: 0 10px;
      margin: 0 10px;
    }
  }
</style>
