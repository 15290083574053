import { reactive, toRefs } from 'vue'
import EsimAdminApi from '@/helpers/esimAdminApi'

export function useEsimAdminApi () {
  const esimApiInst = new EsimAdminApi()
  const esimApiInstances = reactive({
    esimUserApi: null,
    simApi: null
  })

  const initApiInst = async () => {
    await getUserApi()
    await getSimApi()
    await esimApiInst.updateFbToken()
  }

  const getUserApi = async () => {
    if (esimApiInst) {
      esimApiInstances.esimUserApi = await esimApiInst.userApi()
    }
  }

  const getSimApi = async () => {
    if (esimApiInst) {
      esimApiInstances.simApi = await esimApiInst.SimApi()
    }
  }

  return {
    initApiInst,
    ...toRefs(esimApiInstances)
  }
}
