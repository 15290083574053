<template>
  <b-modal
    v-model="showModal"
    @hide="hideModal"
    title="Partner Info"
    centered
    hide-footer
  >
    <div v-if="account && account.user" ref="partnerInfoWrap" class="my-2">
      <div class="userInfoWrap">
        <div v-if="account.user.partnerName">
          <span class="greyText">Name:</span>
          <span @click="copyToCb($refs.name, account.user.partnerName, $refs.partnerInfoWrap)" ref="name" class="cp">
            {{ account.user.partnerName }}
          </span>
        </div>
        <div>
          <span class="greyText">Balance: </span>
          <span class="info">
            ${{ Number(account.user.partnerBalance) ?? 0 }}
          </span>
        </div>
        <div v-if="account.user.partnerCode">
          <span class="greyText">Code:</span>
          <span @click="copyToCb($refs.code, account.user.partnerCode, $refs.partnerInfoWrap)" ref="code" class="cp">
            {{ account.user.partnerCode }}
          </span>
        </div>
        <div v-if="account.user.partnerLink">
          <span class="greyText">Link:</span>
          <span @click="copyToCb($refs.link, account.user.partnerLink, $refs.partnerInfoWrap)" ref="link" class="cp">
            {{ account.user.partnerLink }}
          </span>
        </div>
        <div v-if="account.user.partnerCommissionType">
          <span class="greyText">Commission type:</span>
          <span class="info">
            {{ account.user.partnerCommissionType }}
          </span>
        </div>
      </div>
    </div>
    <div class="btnBl">
      <b-button @click="$store.commit('modal/togglePartnerInfoModal', false)" variant="light">Cancel</b-button>
      <b-button @click="updatePartner" variant="outline-success" class="updatePartnerBtn">Update partner</b-button>
    </div>
  </b-modal>
</template>

<script>
import { useCopy } from '@/use/copy'

export default {
  computed: {
    account () {
      return this.$store.state.search.searchAccount
    },
    showModal: {
      get () {
        return this.$store.state.modal.showPartnerInfoModal
      },
      set () {}
    }
  },
  setup () {
    const { copyToCb } = useCopy()
    return { copyToCb }
  },
  methods: {
    hideModal () {
      if (this.showModal) {
        this.$store.commit('modal/togglePartnerInfoModal', false)
      }
    },
    updatePartner () {
      this.hideModal()
      this.$store.commit('modal/togglePartnerModal', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .userInfoWrap{
    &>div{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      span:not(.greyText) {
        display: inline-block;
        word-break: break-all;
        margin-left: 10px;
      }
    }
    span.greyText {
      color: #7e7e7e;
    }
    .cp{
      cursor: pointer;
    }
  }
  .btnBl{
    margin: 20px 0 5px;
    display: flex;
    justify-content: flex-end;
    .updatePartnerBtn{
      margin-left: 16px;
    }
  }
</style>
