import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import Home from '@/views/Home'
import Login from '@/views/Login'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const auth = getAuth()
  const currentUser = auth.currentUser

  if (requiresAuth && !currentUser) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
