export default {
  namespaced: true,
  state: {
    showPartnerModal: false,
    showPartnerInfoModal: false
  },
  mutations: {
    togglePartnerModal (state, val) {
      state.showPartnerModal = val
    },
    togglePartnerInfoModal (state, val) {
      state.showPartnerInfoModal = val
    }
  }
}
