import * as EsimAdminSdk from '@privatix/esim-admin-sdk-typescript'
import { getAuth } from 'firebase/auth'

// let userApi = null
// let purchasesApi = null

class EsimAdminApi {
  constructor () {
    this.configuration = new EsimAdminSdk.Configuration({
      basePath: process.env.VUE_APP_API_BASE_PATH
    })
  }

  async updateFbToken () {
    const auth = getAuth()
    if (auth.currentUser) {
      this.configuration.accessToken = await auth.currentUser.getIdToken()
    }
  }

  async userApi () {
    if (!this.userApiInst || this.configuration.accessToken !== this.accessToken) {
      this.accessToken = typeof this.configuration.accessToken === 'string' ? this.configuration.accessToken : ''
      this.userApiInst = new EsimAdminSdk.UserApi(this.configuration)
    }
    return this.userApiInst
  }

  async SimApi () {
    if (!this.simApiInst || this.configuration.accessToken !== this.accessToken) {
      this.accessToken = typeof this.configuration.accessToken === 'string' ? this.configuration.accessToken : ''
      this.simApiInst = new EsimAdminSdk.SimApi(this.configuration)
    }
    return this.simApiInst
  }

  // async purchasesApi () {
  //   if (!purchasesApi) {
  //     purchasesApi = new EsimAdminSdk.SimApi()
  //   }

  //   return purchasesApi
  // }
}

export default EsimAdminApi
