<template>
  <router-view/>
  <Alert />
</template>

<script>
import Alert from '@/components/Alert'

export default {
  components: { Alert }
}
</script>

<style lang="scss">
  input,
  button{
    outline: none !important;
    box-shadow: none !important;
  }
  .btn-block{
    display: block;
    width: 100%;
  }
  .mr-2{
    margin-right: 10px;
  }
  .mt-14{
    margin-top: 70px !important;
  }
  .form {
    margin-top: 20px;

    &__control {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .control {
    position: relative;

    &__error {
      padding-top: 16px;
      padding-left: 12px;
      color: #FA3131;
      font-size: 12px;
    }

    input {
      width: 100%;
      border: 1px solid #EFEFEF;
      border-radius: 15px;
      padding: 20px 20px 20px 40px;
      font-size: 14px;
      color: #848194;
      background: #FFF;

      &:focus {
        outline: none;
      }
    }
  }

  .questionModal {
    .confirmBtn {
      margin-left: 20px;
      min-width: 100px;
    }

    .btnBl {
      display: flex;
      justify-content: flex-end;
    }
  }

  .enabledStatus,
  .whiteListedStatus{
    color: #198754 !important;
  }
  .onHoldStatus{
    color: #bd8f05 !important;
  }
  .deletedStatus{
    color: #dc3545 !important;
  }
</style>
